import { useState } from "react";
import styles from "./AdminAuth.module.scss";
import axios from "axios";
import olho from "../../assets/images/png/olho.png";
import olhoFechado from "../../assets/images/png/olho fechado.png";
import EmailRepository from "../../../../infra/api/EmailRepository";

interface errorMap {
  [key: string]: string;
}

const errorMap: errorMap = {
  503: "Base de dados indisponível",
  500: "Oops! Algo deu errado",
  401: "Usuário ou senha inválidos",
  200: "Login efetuado com sucesso",
  ERR_NETWORK: "Falha na conexão com o servidor",
  ERR_BAD_REQUEST: "Requisição inválida",
};

export function AdminAuth() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [sucess, setSuccess] = useState("");
  const runningTimeOuts: any = [];

  const stopTimmer = () => {
    if (runningTimeOuts.length > 0) {
      runningTimeOuts.forEach((timeoutId: any) => {
        clearTimeout(timeoutId);
      });
    }
  };

  const handleReturnError = () => {
    stopTimmer();
    const timeoutId = setTimeout(() => {
      setError("");
    }, 5000);

    runningTimeOuts.push(timeoutId);
  };

  const handleReturnSuccess = () => {
    stopTimmer();
    const timeoutId = setTimeout(() => {
      setSuccess("");
    }, 5000);

    runningTimeOuts.push(timeoutId);
  };

  const handleLaunchError = (message: string) => {
    setError(message);
    handleReturnError();
  };

  const handleLaunchSucess = (message: string) => {
    setSuccess(message);
    handleReturnSuccess();
  };

  const handlesignin = () => {
    setError("");
    setSuccess("");

    if (!/\S+@\S+\.\S+/.test(user)) {
      handleLaunchError("Email inválido");
      return;
    }

    if (password.length < 8) {
      handleLaunchError("Senha deve conter no mínimo 8 caracteres");
      return;
    }

    if (user === "" || password === "") {
      handleLaunchError("Preencha todos os campos");
      return;
    }

    const client = new EmailRepository();
    let response = client.authenticate(user, password);

    response
      .then((data) => {
        handleLaunchSucess(errorMap[200]);
        localStorage.setItem("access_token", data.data.access_token);

        if (document.getElementById("loginKeeper")) {
          const loginKeeper = document.getElementById(
            "loginKeeper"
          ) as HTMLInputElement;

          if (loginKeeper.checked) {
            localStorage.setItem("refresh_token", data.data.refresh_token);
          }
        }

        setTimeout(() => {
          window.location.replace("/admin");
        }, 1000);
      })
      .catch((error) => {
        if (error.status) {
          handleLaunchError(errorMap[error.status]);
        } else if (error.message === "Network Error") {
          handleLaunchError(errorMap.ERR_NETWORK);
        } else {
          handleLaunchError(errorMap.ERR_BAD_REQUEST);
        }
      });
  };

  const handleSeePassword = () => {
    const password = document.getElementById("password") as HTMLInputElement;
    const see = document.getElementById("see") as HTMLImageElement;

    if (password.type === "password") {
      password.type = "text";
      see.src = olhoFechado;
    } else {
      password.type = "password";
      see.src = olho;
    }
  };

  return (
    <main className={styles.mainPageContainer}>
      <form className={styles.authForm} onSubmit={(e) => e.preventDefault()}>
        <h1>Login</h1>

        <section>
          {error && (
            <span className={styles.message} style={{ background: "#af2c0f" }}>
              {error}
            </span>
          )}
          {sucess && (
            <span className={styles.message} style={{ background: "#007000" }}>
              {sucess}
            </span>
          )}
          <label>
            <span>Usuário</span>
            <input
              type="text"
              name="Username"
              onChange={(e) => setUser(e.target.value)}
            />
          </label>

          <label>
            <span>Senha</span>
            <input
              type="password"
              name="Password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <img id="see" src={olho} alt="" onClick={handleSeePassword} />
          </label>

          <label className={styles.rememberButton}>
            <input type="checkbox" id="loginKeeper" />
            <p>Manter login.</p>
          </label>
          <button type="submit" name="Submit" onClick={handlesignin}>
            Login
          </button>
        </section>
      </form>
    </main>
  );
}

import React, { useEffect } from "react";
import styles from "./AdminPage.module.scss";
import logo from "../../assets/images/svg/logo.svg";
import deleteIcon from "../../assets/images/png/delete.png";
import Footer from "../../components/Footer";
import EmailRepository from "../../../../infra/api/EmailRepository";

const AdminPage: React.FC = () => {
  const [token, setToken] = React.useState(
    localStorage.getItem("access_token")
  );
  const [isLoading, setIsLoading] = React.useState(true);
  const [dataDisplay, setDataDisplay]: any[] = React.useState();
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState("Carregando...");

  const api = new EmailRepository();

  const handleSignOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    window.location.reload();
  };

  const handleDeleteData = (id: string) => {
    api.deleteBySenderEmail(id).then(() => {
      handleLoadData();
    });
  };

  const handleLoadData = () => {
    api
      .list()
      .then((data) => {
        if (data.length === 0) {
          setIsLoading(true);
          setLoading("Nenhum email encontrado");
        } else {
          console.log(data);
          setDataDisplay(data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        handleSignOut();
      });
  };

  const handleFilterData = (email: string, name: any = undefined) => {
    setIsLoading(true);
    console.log(email);
    api
      .list({ name, email })
      .then((data) => {
        if (data.length === 0) {
          setLoading("Nenhum email encontrado");
        } else {
          console.log(data);
          setDataDisplay(data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setLoading("Nenhum email encontrado");
      });
  };

  useEffect(() => {
    if (
      search.length > 5 &&
      /\S+@\S+\.\S+/.test(search) &&
      /[a-zA-Z]$/.test(search)
    ) {
      handleFilterData(search);
    } else {
      handleLoadData();
    }
  }, [search]);

  // Carregar dados ao montar a página
  useEffect(() => {
    handleLoadData();
  }, [token]);

  const handleRefreshToken = () => {
    api.refreshToken();
  };

  // export csv
  const columns = [
    { title: "Email do Remetente", field: "senderEmail" },
    { title: "Nome do Remetente", field: "senderName" },
    { title: "Data de Envio", field: "created_at" },
    { title: "Ações", field: "actions" },
  ];

  const csvColumnsArray = [
    { title: "Data de Envio", field: "created_at" },
    { title: "Nome do Remetente", field: "senderName" },
    { title: "Email do Remetente", field: "senderEmail" },
  ];

  const handleExportDataToCsv = () => {
    const csvColumns = csvColumnsArray.map((column) => column.title);
    const csvData = dataDisplay.map((email: any) => {
      return [
        `${new Date(email.createdAt).toLocaleDateString("pt-BR")} - ${new Date(
          email.createdAt
        ).toLocaleTimeString("pt-BR")}`,
        email.senderName,
        email.senderEmail,
      ];
    });

    const csvArray = [csvColumns, ...csvData];
    const csvContent = csvArray
      .map((row) => {
        return row.join(",");
      })
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "emails.csv";
    a.click();
  };

  return (
    <>
      <header className={styles.mainHeader}>
        <img src={logo} alt="logo" />

        <label onClick={handleSignOut}>
          <p>Sair</p>
        </label>
      </header>
      <main className={styles.mainContainer}>
        <div className={styles.tableHeader}>
          <h2>Emails Enviados</h2>
          <input
            type="text"
            name="Pesquisa"
            placeholder="Pesquisar Email"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <button className={styles.exportButton} onClick={handleExportDataToCsv}>
          Exportar Csv
        </button>

        {
          // <button onClick={handleRefreshToken}> Refresh Token</button>
        }

        <section>
          {isLoading ? (
            <p id="loading">{loading}</p>
          ) : (
            <table className={styles.mainTable}>
              <thead>
                <tr>
                  {columns.map((column) => (
                    <th key={column.title}>{column.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataDisplay.map((email: any) => (
                  <tr key={email.senderEmail}>
                    <td>
                      {new Date(email.createdAt).toLocaleDateString("pt-BR")}
                    </td>
                    <td>{email.senderName}</td>
                    <td id="email.id">{email.senderEmail}</td>

                    <td>
                      <a
                        href="#"
                        onClick={() => handleDeleteData(email.id)}
                        className={styles.deleteButton}
                      >
                        Deletar
                        <img
                          src={deleteIcon}
                          alt="Icone Lixeira por: Ilham Fitrotul Hayat"
                        />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>
      </main>
      <Footer />
    </>
  );
};

export default AdminPage;

import SupporterInfo from "../../domain/models/SupporterInfo"
import Email from "../../domain/models/Email"
import EmailFilters from "../../services/filters/EmailFilters"

abstract class EmailPort {
    abstract send(supporterInfo: SupporterInfo): Promise<void>
    abstract getSentNumber(): Promise<number>
    abstract list(filters?: EmailFilters): Promise<Array<Email>>
    abstract deleteBySenderEmail(senderEmail: string): Promise<void>
    abstract contact(email: Email): Promise<void>
}

export default EmailPort

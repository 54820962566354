import React, { useEffect, useRef } from "react";
import styles from "./Contact.module.scss";
import { handlePageHighLight } from "../../contexts/functions";
import triangles from "../../assets/images/svg/cyanTriangle.svg";
import trianglesOnLeft from "../../assets/images/svg/trianglesOnLeft.svg";
import trianglesOnRight from "../../assets/images/svg/trianglesOnRight.svg";
import trianglesMobile from "../../assets/images/svg/trianglesMobile.svg";
import downArrow from "../../assets/images/svg/downArrow.svg";
import paper from "../../assets/images/svg/paper.svg";
import Button from "../../components/Button";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import GetEmailServices from "../../dicontainer/dicontainer";
import Email from "../../../../core/domain/models/Email";

const Contact: React.FC = () => {
  const services = GetEmailServices();
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [acceptPrivacyPolicies, setAcceptPrivacyPolicies] = React.useState(false);
  const [wasEmailSent, setWasEmailSent] = React.useState<boolean>();
  const [isDataValid, setIsDataValid] = React.useState<boolean>();
  useEffect(() => {
    handlePageHighLight("contact");
  });

  async function onSubmit() {
    setIsDataValid(undefined);
    setWasEmailSent(undefined);
    try {
      let _email = new Email({senderName: name, senderEmail: email, body: message});
      if (!_email.isContactValid()) {
        setIsDataValid(false);
        return
      }
      await services.contact(_email);
      setIsDataValid(undefined);
      setWasEmailSent(true);
      console.log("Email sent successfully!");
    } catch (error) {
      setWasEmailSent(false);
      console.error("Error sending email:", error);
    }
  }

  return (
    <>
      <Header />
      <main>
        <section className={styles.pageMainTitleContainer}>
          <figure>
            <img src={triangles} alt="Header triangles" />
          </figure>
          <h1>Entre em contato</h1>

          <img
            src={trianglesOnLeft}
            alt="Left triangles"
            className={styles.pageMainTitleTrianglesOnLeft}
          />
          <img
            src={trianglesOnRight}
            alt="Right triangles"
            className={styles.pageMainTitleTrianglesOnRight}
          />
          <img
            src={trianglesMobile}
            alt="Mobile triangles"
            className={styles.pageMainTitleTrianglesMobile}
          />

          <img
            src={downArrow}
            alt="downArrow"
            className={styles.pageMainTitleDownArrowElement}
          />
        </section>

        <section className={styles.mainFormContainer}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <label className={styles.formInputItem}>
              <p>E-mail</p>
              <input 
                placeholder="Insira seu e-mail" 
                type="email" 
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value)
                }
              />
            </label>

            <label className={styles.formInputItem}>
              <p>Nome</p>
              <input 
                placeholder="Insira seu nome" 
                type="text" 
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setName(e.target.value)
                }
              />
            </label>

            <label className={styles.formInputItem}>
              <p>Mensagem</p>
              <textarea 
                placeholder="Digite aqui..."
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setMessage(e.target.value)
                }
              ></textarea>
            </label>

            <section className={styles.formSubmit}>
              <label className={styles.formCheckbox}>
                <input 
                  type="checkbox"
                  name="politicsCheckbox"
                  checked={acceptPrivacyPolicies}
                  onChange={() => {
                    setAcceptPrivacyPolicies(!acceptPrivacyPolicies);
                  }}
                />
                <div className={styles.formCheckboxCheckbox}>
                  <div className={styles.formCheckboxCheckmark}></div>
                </div>
                <span>
                  Declaro que li e aceito as{" "}
                  <b>
                    <u>
                      <a style={{color: "black"}} href="https://drive.google.com/file/d/1ChML_wQoHYmyf1HamqG1Xy3kqVRZ9zob/view?usp=drive_link" target="_blank">
                        políticas de privacidade
                      </a>
                    </u>
                  </b>
                </span>
              </label>
              <Button 
                text="Enviar" 
                src={paper} 
                hasIcon={true} 
                disabled={!acceptPrivacyPolicies}
              />
            </section>
            {isDataValid === undefined ? (
              ""
            ) : (
              <div className={`${styles.alert} ${styles.red}`}>
                <p>Os dados informados são inválidos!</p>
                <span
                  className={styles.closeButton}
                  onClick={() => {
                    setIsDataValid(undefined);
                  }}
                >
                  &times;
                </span>
              </div>
            )}
            {wasEmailSent === undefined ? (
              ""
            ) : wasEmailSent ? (
              <div className={`${styles.alert}  ${styles.green}`}>
                <p>Email enviado com sucesso!</p>
                <span
                  className={styles.closeButton}
                  onClick={() => {
                    setWasEmailSent(undefined);
                  }}
                >
                  &times;
                </span>
              </div>
            ) : (
              <div className={`${styles.alert} ${styles.red}`}>
                <p>Ocorreu um erro inesperado!</p>
                <span
                  className={styles.closeButton}
                  onClick={() => {
                    setWasEmailSent(undefined);
                  }}
                >
                  &times;
                </span>
              </div>
            )}
          </form>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Contact;

import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from "../pages/Home/Home";
import About from "../pages/About/About";
import Contact from "../pages/Contact/Contact";
import Email from "../pages/Email/Email";
import { AdminAuth } from "../pages/AdminAuth/AdminAuth";
import AdminPage from "../pages/AdminPage/AdminPage";

const Router: any = () => {
  const [isLogged, setIsLogged] = useState(false);

  const handleVerifyToken = () => {
    const token = localStorage.getItem("access_token");

    if (token) {
      setIsLogged(true);
    } else {
      setIsLogged(false);
    }
  };

  useEffect(() => {
    handleVerifyToken();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/email" element={<Email />} />

        <Route
          path="/admin"
          element={isLogged ? <AdminPage /> : <AdminAuth />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

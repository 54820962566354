import EmailPort from "../../core/interfaces/secondary/EmailPort";
import SupporterInfo from "../../core/domain/models/SupporterInfo";
import { Client } from "./Client";
import Email from "../../core/domain/models/Email";
import EmailFilters from "../../core/services/filters/EmailFilters";

class EmailRepository extends EmailPort {
  async send(supporterInfo: SupporterInfo): Promise<void> {
    try {
      const { name, email } = supporterInfo;
      await Client.post("emails", { name, email });
    } catch (error) {
      throw error;
    }
  }

  async getSentNumber(): Promise<number> {
    const response = await Client.get("emails/sent-number");
    return Promise.resolve(response.data.sent_number);
  }

  async list(filters?: EmailFilters): Promise<Array<Email>> {
    let token = localStorage.getItem("access_token");
    let url = "emails";
    if (filters !== undefined) {
      if (filters.name !== undefined && filters.email !== undefined) {
        url += `?senderName=${filters.name}&senderEmail=${filters.email}`;
      } else if (filters.name !== undefined) {
        url += `?senderName=${filters.name}`;
      } else if (filters.email !== undefined) {
        url += `?senderEmail=${filters.email}`;
      }
    }
    const response = await Client.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const emails = new Array<Email>();
    response.data.forEach((element: any) => {
      const id = element.id;
      const senderName = element.sender_name;
      const senderEmail = element.sender_email;
      const subject = element.subject;
      const body = element.body;
      const emailRecipientList = element.email_recipient_list;
      const createdAt = element.created_at;
      const updatedAt = element.updated_at;
      const email = new Email({
        id,
        senderName,
        senderEmail,
        subject,
        body,
        emailRecipientList,
        createdAt,
        updatedAt,
      });
      emails.push(email);
    });
    return Promise.resolve(emails);
  }

  async deleteBySenderEmail(senderEmail: string): Promise<void> {
    const token = localStorage.getItem("access_token");
    try {
      await Client.delete("emails/delete-by-sender-email", {
        data: { sender_email: senderEmail },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (error) {
      throw error;
    }
  }

  async contact(_email: Email): Promise<void> {
    try {
      const name = _email.senderName;
      const email = _email.senderEmail;
      const message = _email.body;
      await Client.post("contact", { name, email, message });
    } catch (error) {
      throw error;
    }
  }

  async refreshToken(): Promise<void> {
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      const token = localStorage.getItem("access_token");
      const response = await Client.post("auth/refresh", {
        refresh_token: refreshToken,
        Headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response);
    } catch (error) {
      throw error;
    }
  }

  async authenticate(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Client.post("auth/user/signin", { email, password })
        .then((response) => {
          if (response.data.access_token) {
            resolve(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

export default EmailRepository;

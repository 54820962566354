import React from "react";
import Email from "../../../core/domain/models/Email";
import "./EmailTable.module.scss";
//import SupporterInfo from "../../../core/domain/models/SupporterInfo";
import GetEmailServices from "../dicontainer/dicontainer";

interface EmailTableProps {
  emails: Email[];
}

const EmailTable: React.FC<EmailTableProps> = ({ emails }) => {
  const services = GetEmailServices();
  async function deleteBySenderEmail(senderEmail: string) {
    try {
      await services.deleteBySenderEmail(senderEmail);
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <table>
      <thead>
        <tr>
          <th>#</th>
          <th>Nome do Remetente</th>
          <th>Email do Remetente</th>
          <th>Criado em</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        {emails.map((email, index) => (
          <tr key={email.id}>
            <td>{index + 1}</td>
            <td>{email.senderName}</td>
            <td>{email.senderEmail}</td>
            <td>{email.createdAt.toLocaleString()}</td>
            <td>
              <button
                onClick={() => {
                  deleteBySenderEmail(email.senderEmail);
                  window.location.reload();
                }}
              >
                Deletar
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EmailTable;

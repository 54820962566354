class Email {
    private _id: string
    private _senderName: string
    private _senderEmail: string
    private _subject: string
    private _body: string
    private _emailRecipientList: Array<string>
    private _createdAt: Date
    private _updatedAt: Date

    constructor(obj: {id?: string, senderName?: string, senderEmail?: string, subject?: string, body?: string,
        emailRecipientList?: Array<string>, createdAt?: Date, updatedAt?: Date}) {
        this._id = ""
        this._senderName = ""
        this._senderEmail = ""
        this._subject = ""
        this._body = ""
        this._emailRecipientList = []
        this._createdAt = new Date()
        this._updatedAt = new Date()
        if (obj.id) {
            this._id = obj.id
        }
        if (obj.senderName) {
            this._senderName = obj.senderName
        }
        if (obj.senderEmail) {
            this._senderEmail = obj.senderEmail
        }
        if (obj.subject) {
            this._subject = obj.subject
        }
        if (obj.body) {
            this._body = obj.body
        }
        if (obj.emailRecipientList) {
            this._emailRecipientList = obj.emailRecipientList
        }
        if (obj.createdAt) {
            this._createdAt = obj.createdAt
        }
        if (obj.updatedAt) {
            this._updatedAt = obj.updatedAt
        }
    }

    get id(): string {
        return this._id;
    }

    set id(value: string) {
        this._id = value;
    }

    get senderName(): string {
        return this._senderName;
    }

    set senderName(value: string) {
        this._senderName = value;
    }

    get senderEmail(): string {
        return this._senderEmail;
    }

    set senderEmail(value: string) {
        this._senderEmail = value;
    }

    get subject(): string {
        return this._subject;
    }

    set subject(value: string) {
        this._subject = value;
    }

    get body(): string {
        return this._body;
    }

    set body(value: string) {
        this._body = value;
    }

    get emailRecipientList(): Array<string> {
        return this._emailRecipientList;
    }

    set emailRecipientList(value: Array<string>) {
        this._emailRecipientList = value;
    }

    get createdAt(): Date {
        return this._createdAt;
    }

    set createdAt(value: Date) {
        this._createdAt = value;
    }

    get updatedAt(): Date {
        return this._updatedAt;
    }

    set updatedAt(value: Date) {
        this._updatedAt = value;
    }

    isSenderNameValid(): boolean {
        if (!this.senderName) {
            return false
        }
        return true
    }

    isSenderEmailValid(): boolean {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(this._senderEmail)
    }

    isBodyValid(): boolean {
        if (!this._body) {
            return false
        }
        return true
    }

    isContactValid(): boolean {
        return this.isSenderNameValid() && this.isSenderEmailValid() && this.isBodyValid()
    }
}

export default Email

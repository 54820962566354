interface MyProps {
  text: string;
  hasIcon: boolean;
  src: any;
  disabled?: boolean;
  onClick?: () => void;
}

const Button = (props: MyProps) => {
  return props.hasIcon !== true ? (
    <button
      className={`mainButtonStyle ${
        props.disabled ? "mainButtonDisabled" : ""
      }`}
      disabled={!!props.disabled}
      onClick={props.onClick}
    >
      {props.text}
    </button>
  ) : (
    <button
      type="submit"
      className={`mainButtonStyleWithIcon ${
        props.disabled ? "mainButtonDisabled" : ""
      }`}
      disabled={props.disabled}
    >
      <span>{props.text}</span>
      <img src={props.src} alt="Page Icon" />
    </button>
  );
};

export default Button;
